import React from 'react';
import { Paper, Container, Grid, Link, Button, TextField } from '@material-ui/core';


const PricingPage = () => (
  <Paper>
<Container>

<h1>Preise</h1>

  <Grid container justifyContent="center" spacing={0}>
       <Grid item xs={3}>
         <div class="card-header">
           <h4 class="my-0 font-weight-normal">kostenlos</h4>
         </div>
         <div class="card-body">
           <h1 class="card-title pricing-card-title">0 &euro;<small class="text-muted">/ Benutzer, Monat</small></h1>
           <ul class="list-unstyled mt-3 mb-4">
             <li>Einzelplatz</li>
             <li>App only</li>
             <li>Email support</li>
           </ul>
           <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free</button>
         </div>
       </Grid>

        <Grid item xs={3}>
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Basis</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">* &euro;<small class="text-muted">/ Benutzer, Monat</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Übertragung ins Inernet</li>
          <li>Einzelplatz</li>
              <li>App und Website</li>
              <li>Priority email support</li>
              <li>Help center access</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-primary">Get started</button>
          </div>
        </Grid>

         <Grid item xs={3}>

           <div class="card-header">
             <h4 class="my-0 font-weight-normal">Enterprise</h4>
           </div>
           <div class="card-body">
     		<p>
     		Benötigen Sie die App oder Webseite in Ihrem Design? oder spezielle Anpassungen? Nehmen Sie mit uns Kontakt auf!
     		</p>
             <button type="button" class="btn btn-lg btn-block btn-primary">Contact us</button>
           </div>

         </Grid>
   </Grid>


</Container>
  </Paper>
);


export default PricingPage;
