import React from 'react';

import { Paper, Container, Link, Button, TextField } from '@material-ui/core';


const TermsPage = () => (
<Paper>
  <Container>

<h1>Nutzungsbedingungen</h1>
<p>
Nutzungsrecht
</p>
<p>
Nutzungsvoraussetzungen und Pflichten des Nutzers
</p>
<p>
Laufzeit und Kaufpreis
</p>
<p>
Systemänderungen

</p>
<p>
Haftung
</p>
<p>
Verfügbarkeit
</p>
<p>
Datenschutz
</p>
<p>
Urheberrechte, Kennzeichnungsrechte uns sonstiges geistiges Eigentum
</p>
<p>
Änderungen der Nutzungsbedingungen
</p>
<p>
Schlussbestimmungen
</p>
  </Container>
</Paper>
);


export default TermsPage;
