import React from 'react';

import { Paper, Container, Link, Button, TextField } from '@material-ui/core';


const PrivacyPage = () => (
<Paper>
  <Container>
<h1>Datenschutz</h1>
<p>

</p>


<p>Name und Kontaktdaten des f&uuml;r die Verarbeitung Verantwortlichen Dominik Roser Eschenbachstr. 12 89584 Ehingen (Donau) 07391504301 app@bigtown.de</p>

<p>Erfassung Ihrer Daten Bei der Nutzung der App teilen Sie uns Informationen mit. Diese Daten erfassen wir, um die Funktionen der App bereitstellen zu k&ouml;nnen. Die Speicherung erfolgt auf Ihrem Smartphone, eine &Uuml;bertragung ins Internet erfolgt nur nachdem Sie diese M&ouml;glichkeit aktiviert haben und dient dem Zweck der &Uuml;bertragung an mit Ihrem Konto verbundene Smartphones.</p>

<p>Berechtigungen der App Zur vollst&auml;ndigen Nutzung der App sind folgende Berechtigungen notwendig:</p>

<p>- Lokale Speicherung von Daten - Internet - Kamera - Push-Notifcations - Standort</p>

<p>Nutzung von Push-Nachrichten Die App nutzt auch Push-Services der Betriebssystemhersteller. Dies sind Kurzmitteilungen, die mit Einwilligung des Nutzers auf dessen Display angezeigt werden. Im Fall der Nutzung der Push-Services wird ein Device-Token von Apple oder eine Registration-ID von Google zugeteilt. Zweck deren Verwendung durch uns ist allein die Erbringung der Push-Services. Es handelt sich hierbei nur um verschl&uuml;sselte, anonymisierte Ger&auml;te-IDs.</p>

<p>Nutzung von Standortdaten Bei der Erstellungen von neuen Eintr&auml;gen k&ouml;nnen diese mit dem aktuellen Standort erg&auml;nzt werden. Der Standort wird nicht automatisiert in regelm&auml;&szlig;igen Abst&auml;nden erfasst. Daher ist es uns nicht m&ouml;glich ein Bewegungsprofil zu erstellen. Die Standorterfassung dient ausschlie&szlig;lich zur Verbesserung der Dokumentation.</p>

<p>Anmeldung Mit der Anmeldung oder Authentifizierung in der App, berechtigen Sie uns, Sie zu identifizieren und uns Zugriff auf spezifische Dienste zu gew&auml;hren. Je nachdem, was im Folgenden angegeben ist, k&ouml;nnen Drittanbieter Anmelde- und Authentifizierungsdienste bereitstellen. In diesem Fall k&ouml;nnen die Apps auf einige von diesen Drittanbietern gespeicherten Daten zu Anmelde- oder Identifizierungszwecken zugreifen.</p>

<p>Wir nutzen in unseren Apps Google Firebase Authentication, einen Dienst von Google (Google Ireland Ltd., Google Building Gordon House, Barrow Street, Dublin 4, Ireland). Firebase Authentication ist ein von Google bereitgestellter Anmelde- und Authentifizierungsdienst. Um den Anmelde- und Authentifizierungsprozess zu vereinfachen, kann Firebase Authentication Identit&auml;tsdienste von Drittparteien benutzen und die Informationen auf ihrer Plattform speichern.</p>

<p>Unsere Apps erm&ouml;glichen Ihnen eine Anmeldung durch das Google-Log-In, eine Verkn&uuml;pfung mit dem Dienstleister Google.</p>

<p>Unsere Apps erm&ouml;glichen Ihnen eine Anmeldung durch das Apple-Log-In, eine Verkn&uuml;pfung mit dem Dienstleister Apple (Apple Inc., 1 Infinite Loop, Cupertino, CA 95014, USA).</p>

<p>Unsere Apps erm&ouml;glichen Ihnen eine Anmeldung durch das Microsoft-Log-In, eine Verkn&uuml;pfung mit dem Dienstleister Microsoft Corporation (One Microsoft Way, Redmond, WA 98052-6399).</p>

<p>Auswertung von Fehlerberichten und Nutzungsdaten Anonymisierte Nutzungsdaten werden zum sicheren Betrieb und zur Weiterentwicklung der App ausgewertet. Diese Daten lassen sich aber nicht mit Ihrer Identit&auml;t in Verbindung bringen. Wir haben keine M&ouml;glichkeiten, diese Daten Ihrer Person zuzuordnen und f&uuml;hren diese Daten auch nicht mit anderen Datenquellen zusammen.</p>

<p>Wir nutzen die Dienste Firebase Crashlytics der Firma Google (https://about.google/), um unsere App zu optimieren. Daten werden anonym und nicht personenbezogen an Google &uuml;bermittelt. Weitere Informationen finden Sie in der Datenschutzerkl&auml;rungen der Firma Google: - https://policies.google.com/privacy - https://firebase.google.com/support/privacy</p>

<p>Hosting und Backend-Infrastruktur Diese Art von Diensten haben zum Zweck, Daten und Dateien zu hosten, damit diese Anwendung verwaltet und verwendet werden kann. Des Weiteren k&ouml;nnen diese Angebote eine vorgefertigte Infrastruktur zur Verf&uuml;gung stellen, die spezifische Funktionen oder ganze Bestandteile f&uuml;r diese Anwendung abwickelt.</p>

<p>S&auml;mtliche Daten im Zusammenhang mit der Nutzung der App/Webseite werden bei der Firma Google (Google Ireland Ltd., Google Building Gordon House, Barrow Street, Dublin 4, Ireland) in einem europ&auml;ischen Rechenzentrum gehostet. Weitere Informationen finden Sie in der Datenschutzerkl&auml;rungen der Firma Google: - https://policies.google.com/privacy - https://firebase.google.com/support/privacy</p>

<p>Weitergabe von Daten an Dritte Grunds&auml;tzlich werden Daten an Dritte nur &uuml;bermittelt, soweit dies gesetzlich vorgeschrieben, durch eine Gerichtsentscheidung festgelegt oder die Weitergabe im Falle von Angriffen auf die Internetinfrastruktur zur Rechts- oder Strafverfolgung erforderlich ist. Eine Weitergabe zu anderen nichtkommerziellen oder zu kommerziellen Zwecken erfolgt nicht.</p>

<p>Betroffenen Rechte Sie haben bez&uuml;glich Ihrer Daten grunds&auml;tzlich folgende Rechte:</p>

<p>- das Recht auf Auskunft zu Ihren Daten - das Recht auf Berichtigung von unrichtigen Daten - das Recht auf L&ouml;schung Ihrer Daten oder das Recht auf Einschr&auml;nkung der Verarbeitung Ihrer Daten - das Recht der Verarbeitung zu widersprechen - das Recht, eine Einwilligung, die Sie abgegeben haben, zu widerrufen.</p>

<p>Dar&uuml;ber hinaus haben Sie ein Beschwerderecht bei der zust&auml;ndigen Datenschutzaufsichtsbeh&ouml;rde.</p>

<p>Aktualit&auml;t und &Auml;nderung dieser Datenschutzinformation Datenschutzerkl&auml;rung Stand 01.07.2021</p>

<p>de.bigtown.app.facilitymanager</p>
</Container>
</Paper>
);


export default PrivacyPage;
