import {
  AlignmentType,
  Document,
  Header,
  Footer,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  ImageRun,
  NumberOfPages,
  NumberOfPagesSection,
  PageNumberFormat,
  PageNumber,
  BorderStyle
} from "docx";
import {
  Link
} from '@material-ui/core';
import saveAs from "file-saver";
import { withFirebase } from '../Firebase';
import imageCompression from 'browser-image-compression'

import * as GLOBALS from '../../constants/globals';

export const MyDocumentDocx = withFirebase(function MyDocument(props) {


      async function getFile(url) {
        try {
          const blob = await fetch(
              url
            )
            .then(r => r.blob());

          const options = {
            //  maxSizeMB: 0.25,          // (default: Number.POSITIVE_INFINITY)
            maxWidthOrHeight: 1240, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
            useWebWorker: true
          }
          const compressedFile = await imageCompression(blob, options);

          const dataUri = await imageCompression.getDataUrlFromFile(compressedFile);

          return dataUri;
        } catch (err) {
          throw err;
        }

      }

      async function generateDocx() {

        var project = props.project;

        const rows = [];
        for (let i = 0; i < project.notes.length; i++) {

          if (project.notes[i].type == 'picture') {
            var img = await getFile(await props.firebase.getFile(project.notes[i].project_id, project.notes[i].filename));

            rows.push(
              new Paragraph({
                children: [
                  new ImageRun({
                    data: img,
                    transformation: {
                      width: 700,
                      height: 700
                    }
                  })
                ]
              })


            );

          } else {
            var rowsText = [];

            project.notes[i].text.split('\n')
              .map((value, index) => {
                rowsText.push(new TextRun({ text: value, break: 1, }));
              });
            rows.push(new Paragraph({
              children: [
                ...rowsText
              ],
              border: {

                bottom: {
                  color: "auto",
                  space: 5,
                  value: BorderStyle.SINGLE,
                  size: 2,
                },
              },
            }));

          }

          // workaround, damit border immer angezeigt wird
          rows.push(new Paragraph({ text: ' ' }));
        }

        const doc = new Document({
          creator: GLOBALS.WEBSITE_TITLE,
          title: project.name,
          description: "",
          sections: [{
            headers: {
              default: new Header({
                children: [new Paragraph(GLOBALS.WEBSITE_TITLE)],
              }),
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        children: [PageNumber.CURRENT, "/", PageNumber.TOTAL_PAGES],
                      })
                    ]
                  }),
                ],
              }),
            },
            children: [
              new Paragraph({
                text: project.name,
                heading: HeadingLevel.HEADING_1,
              }),

              new Paragraph({
                text: "Notizen",
                heading: HeadingLevel.HEADING_3,
              }),
              ...rows,
            ]
          }]
        });

        Packer.toBlob(doc)
          .then((blob) => {
            console.log(blob);
            saveAs(blob, project.name + ".docx");
            console.log("Document created successfully");
          });

      }



      return ( < Link href = "#"
        onClick = {
          () => generateDocx()
        } > { props.children } < /Link> );

      });