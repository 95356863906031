import React, { Component } from 'react';


import { AuthUserContext, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { Link as RouterLink }from 'react-router-dom';
//import { HashLink as RouterLink } from 'react-router-hash-link';
import * as ROUTES from '../../constants/routes';
import Link from '@material-ui/core/Link';
import { Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { DataGrid } from '@material-ui/data-grid';



class TasksListBase extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tasks: [],
    };


  }

  componentDidMount() {

    this.setState({ loading: true });

	this.props.firebase.projects().onSnapshot(function(querySnapshot) {

	  const projectsList = Object.keys( querySnapshot.docs ).map(key => ({
       ...querySnapshot.docs[key].data(),
        uid: key,
      }));


		var tasksList = [];
		projectsList?.forEach(function(entry) {
			entry?.notes?.filter(note => note.type == 'task' )?.forEach(function(note){
				tasksList.push({note: note, project: entry});
			});

		});
	// sortieren
	tasksList.sort(function(a,b){
	  // Turn your strings into dates, and then subtract them
	  // to get a value that is either negative, positive, or zero.
	  return new Date(a.note.date_from) - new Date(b.note.date_from);
	});


	  this.setState({
        tasks: tasksList,
        loading: false,
      });

    }.bind(this));



  }

  componentWillUnmount() {

  }


  render() {
    const { tasks, loading } = this.state;
    var datum = null;
    var date = null;
    var prev = null;

    if(loading==true){
      return ("<div>LOADING</div>");
    }


    //  const columns = [
    //    { field: 'id', headerName: 'ID', width: 70 },
    //    { field: 'name', headerName: 'First name', width: 130 },
    //    { field: 'text', headerName: 'First name', width: 130 }
    //  ];
    //  const rows = tasks.map((node, index) => [{'id': index, 'name': node.name, 'text': node.text }]);

      //return ( <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection /> )

    	return (
        <div>
      {  tasks.length > 0 ?
    <Table size="small">
      <TableHead>
        <TableRow>
        	<TableCell></TableCell>
        	<TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
          {tasks.map( function(task) {

    		datum = new Date(new Date(task.note.date_from).setHours(0,0,0,0));
    		date = ( prev == undefined || ( datum.getTime() !== prev.getTime() ) ) ? datum : '';
    		prev = datum;

        return (
    	<TableRow>
    		<TableCell>{date.toLocaleString([], {
            year: "2-digit",
            month: "2-digit",
            day: "numeric"
        })}
      <br/>
        {new Date(task.note.date_from).toLocaleTimeString([], {hour: "2-digit",
        minute: "2-digit",})}
      </TableCell>
    		<TableCell>
          <Link component={RouterLink} to={`/project/${task.project.id}#${task.note.id}`}>
          {task.note.name != '' && <>task.note.name <br/></> } {task.note.text}
    		  <br/><i>{task.project.name}</i>
          </Link>
        </TableCell>
        </TableRow>
    	  )}
    	)}
      </TableBody>
    </Table>
    : <div>keine Aufgaben vorhanden</div>
    }
    </div>
    	);

  }

}



const TaskPageBase = function(){

  return (		<>
  		<Card>
  				<CardHeader
  				title="Aufgaben"
  				subheader="Notizen und Tätigkeiten in den Objekten"
  				/>
  		</Card>
  			<br/>

        <Paper>
  	       <Container>

          <TasksList />
        </Container>
        </Paper>

  	  </>
  );

}



//const condition = authUser =>
//  authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;

const TasksList = withAuthorization(condition)(TasksListBase);
const TaskPage = withAuthorization(condition)(TaskPageBase);

export default TaskPage ;

//export default withAuthorization(condition)(TaskPage);
