import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";

import {SignUpLink} from "../SignUp";
import {PasswordForgetLink} from "../PasswordForget";

import {withFirebase} from "../Firebase";
import * as ROUTES from "../../constants/routes";

import {Container, Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button, TextField} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

/*
<SignInForm />

<PasswordForgetLink />
<SignUpLink />
*/

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const {email, password} = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .catch(function(error) {
        //error code
        var errorCode = error.code;
        //errod message
        var errorMessage = error.message;
        //show error message
        window.alert("Error : " + errorMessage);
      })
      .then(() => {
        alert("EMAIL verifiziert: " + this.props.firebase.auth.currentUser.emailVerified);
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({error});
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  render() {
    const {email, password, error} = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <TextField
            name="email"
            margin="normal"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Adresse"
          />
        </div>
        <div>
          <TextField
            name="password"
            margin="normal"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Passwort"
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
        >
          anmelden
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = {error: null};
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {}
        });
      })
      .then(() => {
        alert("EMAIL verifiziert: " + this.props.firebase.auth.currentUser.emailVerified);

        this.setState({error: null});
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({error});
      });

    event.preventDefault();
  };

  render() {
    const {error} = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button variant="contained" type="submit">
          Mit Google einloggen
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
class SignInProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {error: null};
  }
  onSubmit = event => {
    alert(
      "Diese Anmeldemöglichkeit ist noch nicht freigeschaltet. Wenden Sie sich an unseren Support"
    );
  };

  render() {
    const {error} = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button variant="contained" type="submit">
          Mit Microsoft einloggen
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export {SignInForm, SignInGoogle, SignInProvider};
