import React, {Component} from 'react';
import {withFirebase} from '../Firebase';
import {AuthUserContext, withAuthorization} from '../Session';
import {v4 as uuidv4} from 'uuid';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

const INITIAL_STATE = {
  name: '',
  description: '',
  customer: '',
  phone: '',
  email: '',
  location: null,
  error: null
};
class NewProjectFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    const {project} = this.props;

    if (project) {
      this.setState({id: project.id, name: project.name, description: project.description, customer: project.customer, phone: project.phone, email: project.email, location: project.location, notes: project.notes});
    } else {
      this.setState({id: uuidv4(), notes: []});
    }
  }

  onSubmit = event => {
    const {
      id,
      submitting,
      name,
      description,
      phone,
      email,
      customer,
      location,
      notes
    } = this.state;

    this.props.firebase.project(id).set({
      id: id,
      name: name,
      customer: customer != '' ? customer : '',

      phone: phone != '' ? phone : '',
      email: email != '' ? email : '',
      location: location != '' ? location : '',
      description: description != '' ? description : '',
      status: "new",
      notes: notes,
      members: { [this.props.firebase.auth.currentUser.uid] : {'approved': true, 'role': 'owner'}},
      created: (new Date()).toISOString(),
      updated: (new Date()).toISOString()
    });

    //this.setState({submitting: true});

    event.preventDefault();


    this.props.handleClose();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const {submitting, name, description, customer, phone, email, location, error} = this.state;

    const isInvalid = name === '';

    if (submitting) {
      return (<> < div > Submtting Form ...</div>
  </>);
    }

    return (<Container>
      <form onSubmit={this.onSubmit}>

        <TextField name="name" margin="normal" fullWidth value={name} onChange={this.onChange} type="text" label="Name"/>
        <br/>
        <TextField name="description" label="Beschreibung" margin="normal" fullWidth value={description} multiline="multiline" rows={3} onChange={this.onChange} type="text" placeholder="Beschreibung"/>
        <br/>
        <TextField name="customer" margin="normal" fullWidth value={customer} onChange={this.onChange} type="text" label="Kontaktdaten Kunde"/>
        <br/>
          <TextField name="phone" margin="normal" fullWidth value={phone} onChange={this.onChange} type="text" label="Telefon"/>
          <br/>
            <TextField name="email" margin="normal" fullWidth value={email} onChange={this.onChange} type="text" label="E-Mail"/>
            <br/>
        <TextField name="location" margin="normal" fullWidth value={location} onChange={this.onChange} type="text" label="Standort"/>
        <br/>
        <Button variant="contained" color="primary" type="submit" disabled={isInvalid}>
          speichern
        </Button>

      </form>
    </Container>);
  }
}

export default function NewProjectDialog(props) {

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (<React.Fragment>
    <Button color="primary" onClick={handleClickOpen}>
      {props.children}
    </Button>
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Projekt hinzufügen</DialogTitle>
      <DialogContent>
        <NewProjectForm handleClose={handleClose} { ... props }/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          schließen
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>);
}

const NewProjectForm = withFirebase(NewProjectFormBase);

export {
  NewProjectForm,
  NewProjectDialog
};
