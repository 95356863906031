import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));
const INITIAL_STATE = {
  feedback: '',

};

class FeedbackFormBase extends Component {

//  const classes = useStyles();
//  const [feedback, setFeedback] = React.useState('');

  //const isInvalid = false;

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {

   }
  onChange = event => {
     this.setState({ [event.target.name]: event.target.value });
  };

   handleSubmit = (evt) => {
      evt.preventDefault();
      this.props.firebase.sendMessage("asdas","asdas",this.state.feedback);
      alert(`Danke für dein Feedback `)
  }


render(){
  const { feedback, error } = this.state;

  const isInvalid =
    feedback === ''
  ;
  return (



    <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>

        <TextField
          id="feedback"
          name="feedback"
          label="Was können wir besser machen? Oder was gefällt?"
          multiline
		  fullWidth
		  margin="normal"
          rows={4}
          defaultValue={feedback}
		  onChange={this.onChange}
        />
<Button  variant="contained" color="primary" type="submit">
Feedback abgeben
</Button>
    </form>
  );
};
}

const FeedbackForm = withFirebase(FeedbackFormBase);

export { FeedbackForm } ;
