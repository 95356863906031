import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import firebase from 'firebase/app'
const INITIAL_STATE = {
  description: '',
  name: '',
  type: 'note',
  filename: '',
  selectedDateFrom: (new Date()).toISOString(),
  selectedDateTo: new Date(new Date().setHours( new Date().getHours() + 1 )).toISOString(),
  error: null,
  submitting: false,
};

class NewNoteFormBase extends Component {

	// var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
	// firestore.timestamp.toDate ...

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
     const { project, note  } = this.props;

     if( note ){


       this.setState({
     		  description: note.text,
     		  name: note.name,
          type: note.type,
     		  filename: note.filename,
          time: this.convertMinuteToHours(note.time).toLocaleString(),
          // TODO: aus default ersetzen
     		  selectedDateFrom: note.date_from,
    // 		  selectedDateTimeFrom: (new Date()).toISOString(),
     		  selectedDateTo: note.date_to,
     //		  selectedDateTimeTo: new Date(new Date().setHours( new Date().getHours() + 1 )).toISOString(),
     		  error: null,
   	   });
     }
   }


onSubmit = event => {
		const { submitting, name, description, type, time, filename, selectedDateFrom, selectedDateTo } = this.state;

var pr = this.props.project;
if(this.props.note){
  var noteIndex = this.props.project.notes.findIndex((obj => obj.id == this.props.note.id));
  var noteSel = pr.notes[noteIndex];

  noteSel.text = description;
  noteSel.name = name;
  noteSel.type = type;
  noteSel.time = time > 0 ? this.convertHoursToMinute(this.parseLocaleNumber(time)) : null;
  noteSel.date_from = (type == 'task') ? new Date( selectedDateFrom).toISOString() : null;
  noteSel.date_to =  (type == 'task') ? new Date(selectedDateTo).toISOString() : null;
  noteSel.type = (filename !== undefined && filename !== '') ? 'picture' : type;
  noteSel.filename = filename;

  pr.notes[noteIndex] = noteSel;
}else{
    var newNote = { id: uuidv4(),
              project_id: this.props.project.id,
              name: name,
              text: description,
              time: time?.length > 0 ? this.convertHoursToMinute(this.parseLocaleNumber(time)) : null,
              type: (filename !== undefined && filename !== '') ? 'picture' : type,
              filename: filename,
              date_from: (type == 'task') ? new Date( selectedDateFrom).toISOString() : null,
              date_to: (type == 'task') ? new Date(selectedDateTo).toISOString() : null,

//              date_from: (type == 'task') ? this.CombineDateAndTime(new Date(selectedDateFrom), new Date( selectedDateTimeFrom)).toISOString() : '',
//              date_to: (type == 'task') ? this.CombineDateAndTime(new Date(selectedDateTo), new Date(selectedDateTimeTo)).toISOString() : '',
              created: (new Date()).toISOString(),
              };

    pr.notes.push(newNote);
}

     this.props.firebase
      .project(this.props.project.id).update(pr);

		 this.setState({
			 submitting: true
		 });

		event.preventDefault();
      this.props.handleClose();
    };

    onChange = event => {
  	   this.setState({ [event.target.name]: event.target.value });
    };

    handleCapture = ({ target }) => {
  		const imageAsFile = target.files[0]

  		if(imageAsFile === '' ) {
  		  console.error(`not an image, the image file is a ${typeof(imageAsFile)}`);
  		}

  		const filename = this.props.firebase.saveFile(this.props.project.id, imageAsFile);

  		this.setState({
  			 filename: filename
  		});

    };
    convertHoursToMinute(hours) {
     return Math.floor(hours * 60);
   };
   convertMinuteToHours(minutes) {
    return minutes / 60;
  };
   parseLocaleNumber(stringNumber, locale) {
      var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
      var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

      return parseFloat(stringNumber
          .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
          .replace(new RegExp('\\' + decimalSeparator), '.')
      );
  };


  render(){
	      const { submitting, name, description, type, time, selectedDateFrom, selectedDateTo, error } = this.state;

        const isInvalid =
          description === '' && time === ''
    	  ;

		return (
		<div>
		  {submitting &&
        <div className="alert alert-success" role="alert">Submtting Form...</div>
      }

          <form onSubmit={this.onSubmit}>

            <RadioGroup aria-label="type" name="type" value={type} onChange={this.onChange}>
              <FormControlLabel value="note" control={<Radio />} label="Notiz" />
              <FormControlLabel value="task" control={<Radio />} label="Aufgabe" />

            </RadioGroup>

		   <TextField
  		   multiline
  		   fullWidth
         rows={4}
  		   id="description"
  		   name="description"
         value={description}
         label="Beschreibung"
         onChange={this.onChange}
       />
       <TextField
  		   fullWidth
  		   id="time"
  		   name="time"
         value={time}
         label="Arbeitszeit"
         helperText="In Stunden z. B. 1,5 = 90 Minuten"
         onChange={this.onChange}
       />

				<input
						accept="image/*"
						style={{display: 'none'}}
						id="icon-button-photo"
						onChange={this.handleCapture}
						type="file"
				/>
				<label htmlFor="icon-button-photo">
						<IconButton color="primary" component="span">
							<PhotoCameraIcon />
						</IconButton>
				</label>
{type=='task' &&
  <>
		<h4>Von</h4>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
		  <Grid container justify="space-around">
			<KeyboardDatePicker
			  disableToolbar
			  variant="inline"
			  format="dd.MM.yyyy"
			  margin="normal"
			  id="date-picker-inline-from"
			  label="Date picker inline"
			  value={selectedDateFrom}
			  onChange={(date)=> this.setState({selectedDateFrom: date})}
			  KeyboardButtonProps={{
				'aria-label': 'change date',
			  }}
			/>

			<KeyboardTimePicker
			  margin="normal"
			  id="time-picker-from"
			  label="Time picker"
			  value={selectedDateFrom}
			  onChange={(date)=> this.setState({selectedDateFrom: date})}
			  KeyboardButtonProps={{
				'aria-label': 'change time',
			  }}
			/>
		  </Grid>
		   </MuiPickersUtilsProvider>
		<h4>bis</h4>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
		  <Grid container justify="space-around">
			<KeyboardDatePicker
			  disableToolbar
			  variant="inline"
			  format="dd.MM.yyyy"
			  margin="normal"
			  id="date-picker-inline-to"
			  label="Date picker inline"
			  value={selectedDateTo}
			  onChange={(date)=> this.setState({selectedDateTo: date})}
			  KeyboardButtonProps={{
				'aria-label': 'change date',
			  }}
			/>

			<KeyboardTimePicker
			  margin="normal"
			  id="time-picker-to"
			  label="Time picker"
			  value={selectedDateTo}
			  onChange={(date)=> this.setState({selectedDateTo: date})}
			  KeyboardButtonProps={{
				'aria-label': 'change time',
			  }}
			/>
		  </Grid>
		   </MuiPickersUtilsProvider>
       </>
}

		<p>
			<Button disabled={isInvalid} variant="contained" color="primary" type="submit">
			  speichern
			</Button>
		</p>
		<br/>


      </form>
</div>

  );
  }
}
 function NewNoteDialog(props){

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Link href="#" aria-label="new note" onClick={handleClickOpen}>
        {props.children}
      </Link>


      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Notiz/Aufgabe hinzufügen</DialogTitle>
        <DialogContent>

			<NewNoteForm handleClose={handleClose} { ... props }/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            schließen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const NewNoteForm = withFirebase(NewNoteFormBase);

export { NewNoteForm, NewNoteDialog } ;
