import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Switch,
  Redirect
} from 'react-router-dom';


import Navigation, {mainListItems} from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import SignOutPage from '../SignOut';

import PasswordChangeForm from '../PasswordChange';

import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import ProjectPage from '../Project';
import ProjectDetailPage from '../ProjectDetail';
import CalendarPage from '../Calendar';
import TaskPage from '../Task';
import EmployeePage from '../Employee';

import ImpressumPage from '../Legal';
import PrivacyPage from '../Legal/PrivacyPage.js';
import PricingPage from '../Legal/PricingPage.js';
import TermsPage from '../Legal/TermsPage.js';

import * as GLOBALS from '../../constants/globals';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { withAuthentication } from '../Session';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';




function Copyright() {
  return (
    <>
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        {GLOBALS.WEBSITE_TITLE}{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
	<Typography variant="body2" color="textSecondary" align="center">
	 <Link component={RouterLink} to={ROUTES.IMPRESSUM}>Impressum</Link>{' '}
	  <Link component={RouterLink} to={ROUTES.PRIVACY}>Datenschutz</Link> {' '}
	   <Link component={RouterLink} to={ROUTES.TERMS}>Nutzungsbedingungen</Link>
    </Typography>
	</>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background : '#ffffff',
    color: '#000000',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: `calc(100vh - 64px)`, // hier ists komisch bzw. ein problem `calc(100vh - 64px)` // 100vh
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


function App() {
	return (
<Router>
    <Switch>
        <Redirect exact from="/" to="/home"/>
        <Route exact path={[
		ROUTES.SIGN_IN ,
		ROUTES.SIGN_UP,
		ROUTES.SIGN_OUT,
		ROUTES.PASSWORD_FORGET,
		ROUTES.PRIVACY,
		ROUTES.IMPRESSUM,
		ROUTES.TERMS,
		ROUTES.PRICING
		]}>
            <AuthLayout>
                <Switch>
                    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
					<Route path={ROUTES.SIGN_OUT} component={SignOutPage} />
					<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
			<Route path={ROUTES.PRIVACY} component={PrivacyPage} />
			 <Route path={ROUTES.IMPRESSUM} component={ImpressumPage} />
			 <Route path={ROUTES.TERMS} component={TermsPage} />
			  <Route path={ROUTES.PRICING} component={PricingPage} />
                </Switch>
            </AuthLayout>
        </Route>
        <Route exact path={[
            ROUTES.HOME,
            ROUTES.ACCOUNT,
					ROUTES.PASSWORD_CHANGE,
           ROUTES.ADMIN,
		   ROUTES.PROJECTS,
		   ROUTES.PROJECT,
		   ROUTES.TASKS,
		   ROUTES.EMPLOYEE,
		   ROUTES.CALENDAR,
        ]}>
            <SiteLayout>
                <Switch>

			  <Route path={ROUTES.HOME} component={HomePage} />
			  <Route path={ROUTES.ACCOUNT} component={AccountPage} />
			  <Route path={ROUTES.PASSWORD_CHANGE} component={PasswordChangeForm} />

			  <Route path={ROUTES.ADMIN} component={AdminPage} />

			  <Route path={ROUTES.PROJECTS} component={ProjectPage} />

			  <Route path={ROUTES.TASKS} component={TaskPage} />
			  <Route path={ROUTES.EMPLOYEE} component={EmployeePage} />

			  <Route path={ROUTES.CALENDAR} component={CalendarPage} />

			  <Route path={ROUTES.PROJECT} component={ProjectDetailPage} />

                </Switch>
            </SiteLayout>
        </Route>
        <Route path="*" component={HomePage}/>
    </Switch>
</Router>
	);
};


const SiteLayout = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        return (
		<>
  <CssBaseline />
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
		  <Box className={classes.title}>
			  <Typography component="h1" variant="h6" color="inherit" >
				  {GLOBALS.WEBSITE_TITLE}
			  </Typography>
			  <Typography variant="subtitle2" gutterBottom>
				  {GLOBALS.WEBSITE_SUBTITLE}
			  </Typography>
		  </Box>

		  <IconButton
		        aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
		  >
              <AccountBoxIcon />
          </IconButton>

		  <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={RouterLink} to={ROUTES.ACCOUNT}>Account</MenuItem>
                <MenuItem component={RouterLink} to={ROUTES.SIGN_OUT}>Logout</MenuItem>
              </Menu>


        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
   <List>{mainListItems}</List>
        <Divider />
        <List></List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>


                {props.children}


          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
	</>
        );
}

const AuthLayout = props => {
		const classes = useStyles();

        return (
    <Container component="main" >
      <CssBaseline />
      <div className={classes.paper}>
 {props.children}
       </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
        );

}



export default withAuthentication(App);
