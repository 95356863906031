export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';

export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';

export const TASKS = '/tasks';

export const PROJECTS = '/projects';
export const PROJECT = '/project/:id';

export const CALENDAR = '/calendar';
export const EMPLOYEE = '/employee';

export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';



export const IMPRESSUM = '/impressum';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const PRICING = '/pricing';