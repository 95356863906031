import React from 'react';
import { Link as RouterLink }from 'react-router-dom';
import { Link }from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';


import SignOutButton from '../SignOut';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Navigation = () => (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
);

export const mainListItems = (
  <div>
    <ListItem button component={RouterLink} to={ROUTES.HOME}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem button component={RouterLink} to={ROUTES.PROJECTS}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Objekte" />
    </ListItem>
    <ListItem button component={RouterLink} to={ROUTES.TASKS}>
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary="Aufgaben" />
    </ListItem>
    <ListItem button component={RouterLink} to={ROUTES.CALENDAR}>
      <ListItemIcon>
        <EventNoteIcon />
      </ListItemIcon>
      <ListItemText primary="Kalender" />
    </ListItem>
    <ListItem button component={RouterLink} to={ROUTES.EMPLOYEE}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Kollegen" />
    </ListItem>
  </div>
);


const NavigationAuth = () => (


<>
 <ul className="navbar-nav mr-auto">
 <li className="nav-item">
      <Link className="nav-link" to={ROUTES.HOME}>Home</Link>
</li>	
	<li className="nav-item">
      <Link className="nav-link" to={ROUTES.PROJECTS}><i className="bi-building"></i> Objekte</Link>
	 </li>
	<li className="nav-item">
      <Link className="nav-link" to={ROUTES.CALENDAR}><i className="bi-calendar"></i> Kalender</Link>
    </li>
	<li className="nav-item">
     <Link className="nav-link" to={ROUTES.TASKS}><i className="bi-list-task"></i> Aufgaben/Notizen</Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to={ROUTES.EMPLOYEE}><i className="bi-person-lines-fill"></i> Kollegen</Link>
    </li>
 </ul>

      <Link className="nav-link" to={ROUTES.ACCOUNT}>Account</Link>


      <SignOutButton />
</>

);
 
const NavigationNonAuth = () => (
<>

      <Link className="nav-link" to={ROUTES.LANDING}>Startseite</Link>
      <Link className="nav-link" to={ROUTES.PRICING}>Preise</Link>
      <Link className="nav-link" to={ROUTES.SIGN_IN}>Einloggen</Link>

</>

);
 
export default Navigation;