import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link  }from '@material-ui/core';
import {Container, Paper, Grid} from '@material-ui/core';


class SignOutPageBase extends Component {
	constructor(props) {
		super(props);
	}
   componentDidMount() {
	this.props.firebase.doSignOut();
   }

  render() {

		return (   <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        style={{ minHeight: '70vh' }}
      >

        <Grid item xs={3}>
          <Paper><Container>
	<h1>Signed out</h1>
  <Link component={RouterLink} to={ROUTES.SIGN_IN}>Einloggen</Link>
  </Container>
</Paper>
</Grid>
</Grid>

		);
  }
}


const SignOutPage = withFirebase(SignOutPageBase);

export default withFirebase(SignOutPage);
