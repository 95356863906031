import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


import firebase from 'firebase/app'
const INITIAL_STATE = {
  error: null,
  submitting: false,
};

class NewPermissionFormBase extends Component {

  // var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
  // firestore.timestamp.toDate ...

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { submitting, email } = this.state;

    this.props.firebase.projectPermission(this.props.project.id, email);

    this.setState({
      submitting: true
    });

    event.preventDefault();
    this.props.handleClose();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value });
  };


  render() {
    const { submitting, email, error } = this.state;

    const isInvalid =
      email === '';

    return ( <
      div > {
        submitting &&
        <
        div className = "alert alert-success"
        role = "alert" > Submtting Form... < /div>
      }

      <
      form onSubmit = { this.onSubmit } >


      <
      TextField fullWidth id = "email"
      name = "email"
      value = { email } label = "E-Mail"

      onChange = { this.onChange }
      />

      <
      p >
      <
      Button disabled = { isInvalid } variant = "contained"
      color = "primary"
      type = "submit" >
      speichern <
      /Button> <
      /p> <
      br / >


      <
      /form> <
      /div>

    );
  }
}

function NewPermissionDialog(props) {

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return ( <React.Fragment >

    <Link href="#" aria-label="new permission" onClick={handleClickOpen}>
      {props.children}
    </Link>

    <Dialog fullWidth = { fullWidth }
      maxWidth = { maxWidth }
      open = { open }
      onClose = { handleClose }
      aria-labelledby = "max-width-dialog-title" >
    <DialogTitle id = "max-width-dialog-title" > Berechtigung hinzufügen </DialogTitle> <DialogContent >

    <NewPermissionForm handleClose = { handleClose } { ...props }
    />

    </DialogContent> <
    DialogActions >
    <Button onClick = { handleClose } color = "primary" >
    schließen </Button> </DialogActions> </Dialog> </React.Fragment>
  );
}

const NewPermissionForm = withFirebase(NewPermissionFormBase);

export { NewPermissionForm, NewPermissionDialog };
