import React, { Component } from 'react';
import { Link as RouterLink }from 'react-router-dom';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import * as ROUTES from '../../constants/routes';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import { NewProjectDialog } from './newProjectForm.js';



import {  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Card, CardHeader, CardContent } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({

});

class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      projects: [],
    };

	    this.addProjectClick = this.addProjectClick.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true });

// orderBy("name") erfordert einen firestore index - bisher nicht möglich
  	this.props.firebase.projects().onSnapshot(function(querySnapshot) {

      const projectsList = Object.keys( querySnapshot.docs ).map(key => ({
       ...querySnapshot.docs[key].data(),
        uid: key,
      }));

	    this.setState({
        projects: projectsList,
        loading: false,
      });

    }.bind(this));

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  addProjectClick() {

  }



  render() {
    const { projects, loading } = this.state;

	if (loading) {
			return (<div>Loading ...</div>);
	}else{
		return (
		<>
			<Card>
				<CardHeader
				title="Objekte"
				subheader="Freigegebene Einheiten"
				/>
			</Card>
      <br/>
      <Container>
        <NewProjectDialog>
          <CreateIcon/> Neues Projekt anlegen</NewProjectDialog>
      </Container>
			 <br/>
			 <ProjectList  projects={ projects} />
			 <br/>
      <Container>
        <NewProjectDialog>
          <CreateIcon/> Neues Projekt anlegen</NewProjectDialog>
      </Container>

		  </>
		);
	}
  }

}


 function ProjectList({ projects }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMore = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
       setAnchorEl(null);
     };
     const handleClick = (projectId, action) => {
        alert(projectId + " " + action);
      };


    return (
        <TableContainer component={Paper}>
        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Kunde</TableCell>
        	    <TableCell>Aufgaben</TableCell>
        	    <TableCell>Notizen</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {projects.map(project => (
            <TableRow key={project.id} hover={true} >
              <TableCell><Link component={RouterLink} to={`/project/${project.id}`}>{project.name}</Link> <br/> {project.description}</TableCell>
              <TableCell>{project.customer}</TableCell>
        	    <TableCell>{project?.notes?.filter(note => note.type == 'task' )?.length}</TableCell>
        	    <TableCell>{project?.notes?.length}</TableCell>
              <TableCell>
  { /*              <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClickMore}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleCloseMore}
>
  <MenuItem onClick={() => handleClick(project.id,'pdf')}>PDF</MenuItem>
  <MenuItem onClick={() => handleClick(project.id,'permission')}>Berechtigung</MenuItem>
  <MenuItem onClick={() =>  handleClick(project.id,'complete')}>Abschließen</MenuItem>
</Menu>
*/ }
    </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
);
}


//const condition = authUser =>
//  authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectPage);
