import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

const ImpressumPage = () => (
	<Paper>
	<Container>
	
		<h1>Impressum</h1>
		<p>
		Dominik Roser <br/>
		Eschenbachstr. 12 <br/>
		89584 Ehingen <br/>
		<br/>

		</p>



	</Container>
	</Paper>
);

 

export default ImpressumPage;