import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link, Button, TextField } from '@material-ui/core';

const PasswordForgetPage = () => (
  <Paper>
  <Container>
    <h1>Passwort vergessen</h1>
    <PasswordForgetForm />
  </Container>
  </Paper>
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
};
 
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value    });
  };
 
  render() {
    const { email, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
	  <div>
        <TextField
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Adresse"
        />
		</div>
		<div>
        <Button variant="contained" color="primary" disabled={isInvalid} type="submit">
          Passwort zurücksetzen
        </Button>
		</div>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
 
const PasswordForgetLink = () => (
  <p>
    <Link component={RouterLink} to={ROUTES.PASSWORD_FORGET}>Passwort vergessen?</Link>
  </p>
);
 
export default PasswordForgetPage;
 
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
 
export { PasswordForgetForm, PasswordForgetLink };