import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link  }from '@material-ui/core';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';
import { SignInGoogle } from '../SignIn/components.js';
import {Container, Paper, Grid} from '@material-ui/core';

import {Button, TextField, Checkbox, FormControlLabel } from '@material-ui/core';


const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  privacy: '',
  error: null,
};

const SignUpPage = () => (
  <Grid
    container
    spacing={0}
    direction="row"
    alignItems="center"
    justify="center"
    spacing={3}
    style={{ minHeight: '70vh' }}
  >

    <Grid item xs={4}>
      <Paper><Container>
<Link component={RouterLink} to={ROUTES.SIGN_IN}>Einloggen</Link>
<br/>
<SignInGoogle/>

      </Container></Paper>

    </Grid>
    <Grid item xs={4}>

  <Paper>
   <Container>
    <h1>Registrieren</h1>
    <SignUpForm />
  </Container>
  </Paper>
  </Grid>
  </Grid>
);


class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, privacy } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
          this.props.firebase.sendVerificationEmail();
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(() => {

        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value    });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
	  privacy,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
	  privacy !== true
	  ;

    return (
      <form onSubmit={this.onSubmit}>
<div>
         <TextField
          name="username"
		  margin="normal"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Name"
        />
</div>
<div>
        <TextField
          name="email"
		  margin="normal"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Adresse"

        />
</div>
<div>
        <TextField
          name="passwordOne"
		  margin="normal"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Passwort"

        />
</div>
<div>
        <TextField
		margin="normal"
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Passwort wiederholen"

        />
</div>
<div>
      <FormControlLabel
        control={
          <Checkbox
            value={privacy}
            onChange={this.onChange}
            name="privacy"
            color="primary"
          />
        }
        label="Datenschutzbedingungen akzeptieren"
      /> (<Link component={RouterLink} to={ROUTES.PRIVACY} target="_blank">Datenschutzbedingungen anzeigen</Link>)
</div>
        <Button variant="contained" color="primary" type="submit" disabled={isInvalid}>registrieren</Button>

        {error && <p>{error.message}</p>}
        <br/><br/>
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Noch keinen Account? <Link component={RouterLink} to={ROUTES.SIGN_UP}>registrieren</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));
export default SignUpPage;

export { SignUpForm, SignUpLink };
