import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';

import * as ROUTES from '../../constants/routes';

import { Paper, Grid, Container, Card, CardHeader, Button } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import Calendar from '@toast-ui/react-calendar';
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

const myTheme = {
  // Theme object to extends default dark theme.
}

const getDate = (type, start, value, operator) => {
  start = new Date(start);
  type = type.charAt(0)
    .toUpperCase() + type.slice(1);

  if (operator === '+') {
    start[`set${type}`](start[`get${type}`]() + value);
  } else {
    start[`set${type}`](start[`get${type}`]() - value);
  }

  return start;
};


class CalendarPage extends Component {
  calendarRef = React.createRef();
  calendarInstance = null;

  state = {
    dateRange: ' -AUSGEWÄHLTER MONAT- ',
    loading: true,
    calendarList: [],
    calendarEntryList: [],
  };

  componentDidMount() {
    this.calendarInstance = this.calendarRef.current.getInstance();

    this.setRenderRangeText();

    this.props.firebase.projects()
      .onSnapshot(function (querySnapshot) {

        const projectsList = Object.keys(querySnapshot.docs)
          .map(key => ({
            ...querySnapshot.docs[key].data(),
            uid: key,
          }));

        var today = new Date();
        var calendarList = [];
        var calendarEntryList = [];
        var count = 0;
        var h = 1;
        var prCount = 0;
        var colors = ['#ff5733', '#2e9dd8', '#2ed888', '#d4c1d4', '#d4d2c1', '#6cf1bb', '#fa52ab', '#ce55ff', '#178eff', '#FFC300', '#FF5733'];
        projectsList?.forEach(function (project) {

          calendarList.push({
            id: project.id,
            name: project.name,
            bgColor: colors[prCount],
            borderColor: colors[prCount]
          });

          prCount++;

          project?.notes?.forEach(function (note) {
            count++;
            h = 1;
            calendarEntryList.push({
              id: note.id,
              calendarId: project.id,
              title: note.text,
              isReadOnly: project.status == 'complete' ? true : false,
              category: 'time',
              dueDateClass: '',
              start: note.date_from ? new Date(note.date_from)
                .toISOString() : new Date(note.created)
                .toISOString(),
              end: note.date_to ? new Date(note.date_to)
                .toISOString() : new Date(note.created)
                .toISOString()
            });
          });

        });

        this.setState({
          calendarList: calendarList,
          calendarEntryList: calendarEntryList,
          loading: false,
          projects: projectsList,
        });

      }.bind(this));

  }

  handleClickDayname = (ev) => {
    // view : week, day
    console.group('onClickDayname');
    console.log(ev.date);
    console.groupEnd();
  };
  handleClickTodayButton = (ev) => {
    // view : week, day
    console.group('onClickToday');
    console.log(ev.date);
    console.groupEnd();

    this.calendarInstance.today();
    this.setRenderRangeText();
  };
  handleClickNextButton = (ev) => {
    // view : week, day
    console.group('onClickNext');
    console.log(ev.date);
    console.groupEnd();

    this.calendarInstance.next();

    this.setRenderRangeText();
  };
  handleClickPrevButton = (ev) => {
    // view : week, day
    console.group('onClickPrev');
    console.log(ev.date);
    console.groupEnd();

    this.calendarInstance.prev();
    this.setRenderRangeText();

  };

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };


  onClickSchedule(event) {
    alert("onClickSchedule");
  };

  onBeforeUpdateSchedule(event) {
    alert("onBeforeUpdateSchedule");

    var schedule = event.schedule;
    var changes = event.changes;

    var project = this.state.projects.find(x => x.id === event.schedule.calendarId);

    var noteIndex = project.notes.findIndex(x => x.id === event.schedule.id);
    var note = project.notes[noteIndex];

    note.date_from = new Date(event.start)
      .toISOString();
    note.date_to = new Date(event.end)
      .toISOString();

    project.notes[noteIndex] = note;

    console.log('beforeUpdateSchedule', event);

    this.props.firebase.project(event.schedule.calendarId)
      .update(project)
      .then((snapshot) => {

      });
    //this.calendarInst.updateSchedule(schedule.id, schedule.calendarId, changes);
  };
  setRenderRangeText() {
    const view = this.calendarInstance.getViewName();

    const calDate = this.calendarInstance.getDate();
    const rangeStart = this.calendarInstance.getDateRangeStart();
    const rangeEnd = this.calendarInstance.getDateRangeEnd();
    let year = calDate.getFullYear();
    let month = calDate.getMonth() + 1;
    let date = calDate.getDate();
    let dateRangeText = '';
    let endMonth, endDate, start, end;

    switch (view) {
    case 'month':
      dateRangeText = `${month}.${year}`;
      break;
    case 'week':
      year = rangeStart.getFullYear();
      month = rangeStart.getMonth() + 1;
      date = rangeStart.getDate();
      endMonth = rangeEnd.getMonth() + 1;
      endDate = rangeEnd.getDate();

      start = `${date < 10 ? '0' : ''}${date}.${month < 10 ? '0' : ''}${month}.${year}`;
      end = `${endDate < 10 ? '0' : ''}${endDate}.${endMonth < 10 ? '0' : ''}${endMonth}.${year}`;
      dateRangeText = `${start} - ${end}`;
      break;
    default:
      dateRangeText = `${date}.${month}.${year}`;
    }
    this.setState({ dateRange: dateRangeText });
  };

  render() {
    const { dateRange, calendarList, calendarEntryList, loading } = this.state;

    var today = new Date();

    //  const {dateRange, view, viewModeOptions} = this.state;
    //const selectedView = view || this.props.view;
    // month week day
    const selectedView = 'week';



    return ( <>
      <Card>
      <CardHeader title = {'Kalender ' + dateRange}
      subheader = "Aufgaben und Tätigkeiten in den Objekten" />
    </Card> <br/>

      <Paper>

      <Button onClick = { this.handleClickPrevButton } ><NavigateBefore/> </Button>
      <Button onClick = { this.handleClickTodayButton } > Heute </Button> 
      <Button onClick = { this.handleClickNextButton } > <NavigateNext /> </Button>



      <Calendar
      ref = { this.calendarRef }
      usageStatistics = { false }
      onClickSchedule = { this.onClickSchedule }
      onBeforeUpdateSchedule = { this.onBeforeUpdateSchedule.bind(this) }
      height = "1400px"

      useDetailPopup = { false }
      useCreationPopup = { false }
      calendars = { calendarList }
      schedules = { calendarEntryList }
      disableDblClick = { true }
      disableClick = { false }
      isReadOnly = { false }
      month = {
        {
          startDayOfWeek: 0
        }
      }


      onClickDayname = { this.handleClickDayname }
      view = { selectedView }
      scheduleView = { true }
      taskView = { false }

      week = {
        {
          showTimezoneCollapseButton: false,
          timezonesCollapsed: false,
          daynames: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          narrowWeekend: true
        }
      }

      template = {
        {
          task: function (schedule) {
            return '#' + schedule.title;
          },
          taskTitle: function () {
            return '<span class="tui-full-calendar-left-content">TASK</span>';
          },
          timegridDisplayPrimayTime: function (time) {
            var hour = time.hour;
            return hour + ' Uhr ';
          },
          alldayTitle: function () {
            return '<span class="tui-full-calendar-left-content">ganzer Tag</span>';
          },
          time: function (schedule) {
            var html = [];

            html.push(schedule.title);

            if(schedule.isReadOnly == true) html.push( ' (geschlossen)');

            return html.join('');
          },
        }
      }
      />

      </Paper>
      </>
    );
  }
}


//const condition = authUser =>
//  authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;

export default withAuthorization(condition)(CalendarPage);
