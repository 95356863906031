
import React from 'react';
import {
  Link
} from '@material-ui/core';
import { Page, Text, View, Document, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import * as GLOBALS from '../../constants/globals';
import { withFirebase } from '../Firebase';
import imageCompression from 'browser-image-compression'


// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,

  },
  author: {
    fontSize: 12,
    textAlign: 'center',

  },
  subtitle: {
    fontSize: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',

  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },

  note: {
    fontSize: 12,
    marginBottom: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: '1px solid grey',
    color: 'grey',
  },
  noteInfo: {
    fontSize: 10,
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});


 const MyDocumentPDF = withFirebase(function MyDocument(props) {


    async function buildPDF(project) {

        const rows = [];
        for (let i = 0; i < project.notes.length; i++) {
          var note = project.notes[i];
          if (project.notes[i].type == 'picture') {
      //      var img = await getFile(await props.firebase.getFile(note.project_id, note.filename));
            var img = await props.firebase.getFile(note.project_id, note.filename);

            rows.push(<Image src={img}/>);

          } else {

            rows.push(<View>
                <Text style={styles.noteInfo}>{new Date(note.created).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})}</Text>
                  <Text style={styles.note}>{note.text}</Text>
              </View>);

          }


        }

        const documentData = ( <Document
          title={project.name}
          author={GLOBALS.WEBSITE_TITLE}
          subject={project.name}
          keywords={[]}>
          <Page size="A4" style={styles.body}>
            <Text style={styles.header} >{GLOBALS.WEBSITE_TITLE}</Text>

              <Text style={styles.title} >{project.name}</Text>
                <Text style={styles.subtitle} >{project.customer} {project.location}</Text>
                  <Text style={styles.subtitle} >{project.description}</Text>

              <Text>{project.description}</Text>

              {rows}

              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
          </Page>
        </Document> );
        return documentData;
      }

      async function generatePDF() {
        var project = props.project;

        const fileName = project.name + ".pdf";

        const blob = await pdf(await buildPDF(project)).toBlob();
        saveAs(blob, fileName);
      }



      return ( <Link href="#" onClick= {
          () => generatePDF()
        } > {props.children} < /Link> );

});

export { MyDocumentPDF } ;
