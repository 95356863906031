import React, {Component, useState, useEffect } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Redirect} from "react-router-dom";
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import {AuthUserContext, withAuthorization} from '../Session';
import {v4 as uuidv4} from 'uuid';

import Divider from '@material-ui/core/Divider';
import {ConfirmDialog} from '../Helper/confirmDialog.js';
import firebase from 'firebase/app'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DeleteIcon from '@material-ui/icons/Delete';
import TimerIcon from '@material-ui/icons/Timer';

import ImageIcon from '@material-ui/icons/Image';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import GroupIcon from '@material-ui/icons/Group';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';
import MapIcon from '@material-ui/icons/Map';

import {
  Link,
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';


import {PDFDownloadLink, Document, Page} from '@react-pdf/renderer'

import {NewNoteDialog} from '../Project/newNoteForm.js';
import {NewProjectDialog} from '../Project/newProjectForm.js';

import {NewPermissionDialog} from '../Project/newPermission.js';

import {MyDocumentDocx} from '../export/docx.js';
import {MyDocumentPDF} from '../export/pdf.js';

import imageCompression from 'browser-image-compression'

class ProjectDetailPage extends Component {
  constructor(props) {
    super(props);

    this.projectDelete = this.projectDelete.bind(this);
    this.noteDelete = this.noteDelete.bind(this);
    this.projectComplete = this.projectComplete.bind(this);
    this.state = {
      loading: true,
      project: null,
      loadingNotes: true,
      notes: null
    };

  }

  componentDidMount() {

    const {match: {
        params
      }} = this.props;

    this.setState({loading: true});

    var projectD;

    this.props.firebase.project(params.id).onSnapshot(function(querySnapshot) {

      projectD = querySnapshot.data();
      if (projectD) {
        this.setState({project: projectD, notes: projectD.notes, loading: false, loadingNotes: false});
      } else {
        this.setState({redirect: ROUTES.PROJECTS});
      }
    }.bind(this));
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  projectDelete(object) {
    this.props.firebase.projectDelete(object.id).then((snapshot) => {
      alert(snapshot);
    });
  }
  projectComplete() {
    this.props.firebase.projectComplete(this.state.project.id).then((snapshot) => {
      this.setState({redirect: ROUTES.PROJECTS});
    });
  }

  noteDelete(object) {
    this.props.firebase.projectNoteDelete(object);
    //this.props.firebase.project(object.project_id).update({notes: firebase.firestore.FieldValue.arrayRemove(object)}).then((snapshot) => {
      // FEEDBACK !
    //});
  }

  render() {

    const {loading, project, loadingNotes, notes} = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    if (loading) {
      return (<div>Loading ...</div>);
    } else {

      var title = project.name;
      if(project.status == 'complete'){
        title = title + ' (abgeschlossen)';
      }
      return (<> < Grid container spacing = {
        3
      } > <Grid item="item" xs={9}>
<Paper>
        <Container>
          <CardHeader title={title} subheader={project.customer} action={<Grid container direction = "row" alignItems = "center" > <Grid item="item">
              <NewProjectDialog project={project}>
                <EditIcon fontSize="small"/>
                Projekt bearbeiten
              </NewProjectDialog>
            </Grid>
            <Grid item="item">
              <DeleteDialog object={project} onDelete={this.projectDelete}> <DeleteIcon/> </DeleteDialog>
            </Grid>
          </Grid>}/>

            {project.customer}
            <br/>
              {project.phone} {project.email && <Link target="_blank" href={"mailto:"+project.email}>E-Mail</Link> }
              <br/>
            {project.location && <div>{project.location}<Link href={"https://www.google.com/maps/search/?api=1&query=" + encodeURI(project.location)} target="_blank"><MapIcon fontSize="inherit"/></Link></div> }
            <br/><br/>
            {project.description}


        </Container>

        <Container>
          <h4>Bilder</h4>
            {

              notes.length > 0
                ? notes.filter(function (el) {
    return el.type == 'picture';
  })
                  ?.map(note => {
                    return <NotePicture project_id={note.project_id} filename={note.filename}/>
                  })
                  : <div>keine Bilder vorhanden</div>
            }

        </Container>
          <Container>
            <h4>Notizen</h4>
            {
              loadingNotes
                ? (<div>Loading</div>)
                : (<div>

                  {
                    notes.length > 0 && true == false
                      ? <Tabs value={0} indicatorColor="primary" textColor="primary" aria-label="disabled tabs example">
                          <Tab label="Notizen"/>
                          <Tab label="Aufgaben"/>
                          <Tab label="Dateien"/>
                        </Tabs>
                      : ''
                  }

                  {
                    notes.length > 0
                      ? notes
                        ?.map(note => {
                          return <Note key={note.id} project={project} note={note} noteDelete={this.noteDelete}/>
                        })
                        : <p>keine Notizen vorhanden</p>
                  }
<br/>
                </div>)
            }

          </Container>
        </Paper>
        <br/>
        <Container>
          <NewNoteDialog project={project}><Button><EditIcon fontSize="inherit"/> Neue Notiz</Button></NewNoteDialog>
        </Container>

      </Grid>

      <Grid item="item" xs={3}>
        <h4><AppsIcon fontSize="inherit"/> Aktionen</h4>
        <p>
          <NewNoteDialog project={project}>Neue Notiz</NewNoteDialog><br/>

          <Link href="#" onClick={this.projectComplete}>Projekt archivieren</Link><br/>
<br/>
          <MyDocumentDocx project={project}>Docx erstellen</MyDocumentDocx>
          <br/>
          <MyDocumentPDF project={project}>PDF erstellen</MyDocumentPDF>
        </p>

        <h4><GroupIcon fontSize="inherit"/> Mitarbeiter</h4>
{Object.keys(project.members).length} Mitarbeiter
{project.members.length > 0 && <b>Anzahl {project.members.length}</b>}
        {
          //project.members.map(function(value, index, array) {
            //return <Contact key={index} contact={value}/>
          //})
        }
        <br/>
<NewPermissionDialog project={project}>Berechtigung erteilen</NewPermissionDialog>
      </Grid>
    </Grid>


  </>);
    }
  }

}

class NoteBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null
    };
  }

  async getFile(url) {
    try {
      const blob = await fetch(url).then(r => r.blob());

      const options = {
        maxSizeMB: 0.25, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1240, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: false
      }
      const compressedFile = await imageCompression(blob, options);
      const dataUri = await imageCompression.getDataUrlFromFile(compressedFile);

      this.setState({imageUrl: dataUri});

      return dataUri;
    } catch (err) {
      throw err;
    }

  }

  componentDidMount() {

    if (this.props.note.filename !== undefined && this.props.note.filename !== '') {

      this.props.firebase.getFile(this.props.note.filename).then((url) => {
        this.getFile(url);
      });

    }

  }
  convertMinsToHrsMins(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m + 'h';
  }

  renderIcon(param) {
    switch (param) {
      case 'picture':
        return (<ImageIcon/>);
      case 'task':
        return (<BookmarkIcon/>);
      default:
        return <></>;
    }
  }

  render() {
    const {imageUrl} = this.state;

    return <div><a name={this.props.note.id}></a>

      <p>{new Date(this.props.note.created).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'})}
        {this.renderIcon(this.props.note.type)} { this.props.note.time > 0 && <><TimerIcon fontSize="inherit"></TimerIcon> {this.convertMinsToHrsMins(this.props.note.time)}</>}
      </p>
      <p>{this.props.note.name}<br/> {
          this.props.note.text.split('\n').map((value, index) => {
            return (<span key={index}>
              {value}
              <br/>
            </span>);
          })
        }
        {this.props.note.state == 'completed' && <><b>ERLEDIGT</b></>}
      </p>
    { ( this.props.note.comment != null && this.props.note.comment.length > 0) && <p>Kommentar: <i>{this.props.note.comment}</i></p>}
      {
        imageUrl !== null
          ? <img src={imageUrl} width={200}/>
          : ''
      }
      {
        this.props.note.date_from != null && this.props.note.date_from != ''
          ? <small>{new Date(this.props.note.date_from).toLocaleString()}
              - {new Date(this.props.note.date_to).toLocaleString()}</small>
          : ""
      }

      <Grid container="container" direction="row" justify="flex-end">
        <Grid item="item">
          <NewNoteDialog project={this.props.project} note={this.props.note}>

            <EditIcon fontSize="small"/>

          </NewNoteDialog>
        </Grid>
        <Grid item="item">
          <DeleteDialog object={this.props.note} onDelete={this.props.noteDelete}> <DeleteIcon fontSize="small"/> </DeleteDialog>

        </Grid>
      </Grid>

      <Divider/>
    </div>;
  }
}
const Note = withFirebase(NoteBase);

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false)

  return (
    <>
    <Button aria-label="delete" onClick={() => setConfirmOpen(true)}>
      {props.children}
    </Button>
    <ConfirmDialog title="Eintrag löschen?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={() => props.onDelete(props.object)}>Möchten Sie den Eintrag wirklich löschen
    ? </ConfirmDialog>
</>);
}

const Contact = withFirebase(function Contact(props) {
  var user;

  props.firebase.user(props.contact).on('value', snapshot => {
    user = snapshot.val();
  });

  if (user == null) {
    return (<> < />);
	}else{
		return (

		<div className="card w-25"><div className="card-body">{user.email}</div > </div>);
  }
});

const NotePicture = withFirebase(function NotePicture(props) {
/*
async getFile(url) {
  try {
    const blob = await fetch(url).then(r => r.blob());

    const options = {
      maxSizeMB: 0.25, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1240, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: false
    }
    const compressedFile = await imageCompression(blob, options);
    const dataUri = await imageCompression.getDataUrlFromFile(compressedFile);

    this.setState({imageUrl: dataUri});

    return dataUri;
  } catch (err) {
    throw err;
  }

}
*/
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {

     async function getFile(url) {
       try {
         const blob = await fetch(url).then(r => r.blob());

         const options = {
           maxSizeMB: 0.25, // (default: Number.POSITIVE_INFINITY)
           maxWidthOrHeight: 1240, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
           useWebWorker: false
         }
         const compressedFile = await imageCompression(blob, options);
         const dataUri = await imageCompression.getDataUrlFromFile(compressedFile);
         setImageUrl(dataUri);

         //return dataUri;
       } catch (err) {
         throw err;
       }
     }
     props.firebase.getFile(props.filename).then((url) => {
       getFile(url);
     });
  }, [])

if(imageUrl != null){
  return ( <><img width={80} src={imageUrl}></img> </>)
}
  return ( <></>)
  // Remember to handle the first render when token is null

});

//const condition = authUser =>
//  authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectDetailPage);
