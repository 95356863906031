import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
//import reportWebVitals from './reportWebVitals';
import Firebase, { FirebaseContext } from './components/Firebase';


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import purple from '@material-ui/core/colors/purple';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  /*  palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
    */
  });

ReactDOM.render(
  <React.StrictMode>
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//ReactDOM.render(
//  <React.StrictMode>
//  <FirebaseContext.Provider value={new Firebase()}>
//    <App />
//  </FirebaseContext.Provider>,
//  </React.StrictMode>,
//  document.getElementById('root')
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
