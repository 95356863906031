import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

var firebaseConfigX = {
  apiKey: "AIzaSyBM8-TPZJ1LYEJPIAvZalVA9smo7rNcXxY",
  authDomain: "app.mieter.net", // hausmeisterapp-298714.firebaseapp.com
  databaseURL:
    "https://hausmeisterapp-298714-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hausmeisterapp-298714",
  storageBucket: "hausmeisterapp-298714.appspot.com",
  messagingSenderId: "270108821160",
  appId: "1:270108821160:web:49144c8a2b371c5b58249d"
};

const firebaseConfig = {
  apiKey: "AIzaSyA9BerHMn6Vu_ARiUqLQBp4YwafsIbBLME",
  authDomain: "handwerk-doku.firebaseapp.com",
  databaseURL: "https://handwerk-doku.firebaseio.com",
  projectId: "handwerk-doku",
  storageBucket: "handwerk-doku.appspot.com",
  messagingSenderId: "112288554764",
  appId: "1:112288554764:web:205031f0a5dc9a29473329",
  measurementId: "G-BPNPNWCCCN"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.realtimedb = app.database();
    this.firestore = app.firestore();
    this.storage = app.storage();
    this.functions = app.app().functions("europe-west3"); // hausmeister west6

    this.googleProvider = new app.auth.GoogleAuthProvider();

    //	app.analytics();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password); // hier muss eigentlich abgefragt werden ob email verifiziert ist

  doSignInWithGoogle() {
    return this.auth.signInWithPopup(this.googleProvider);
  }

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = uid => this.realtimedb.ref(`users/${uid}`);

  users = () => this.realtimedb.ref("users");

  sendVerificationEmail() {
    // extracting the user from the firebase
    var user = this.auth.currentUser;

    user
      .sendEmailVerification()
      .then(function() {
        alert(
          "Verification link sent to your email. Kinldy check to verify your account"
        );
      })
      .catch(function(error) {
        // An error happened.
      });
  }

  sendMessage(category, subject, text) {
    // in Datenbank eintragen
    var docRef = this.firestore.collection("mail").doc();

    docRef.set({
      to: "d.roser@bigtown.de",
      replyTo: this.auth.currentUser.email,
      message: {
        subject: category + " " + subject,
        text: text
      },
      uid: this.auth.currentUser.uid,
      created: new Date().toISOString()
    });
  }

  saveFile(projectId, file) {
    let d = new Date();
    let filename = `/projects/${projectId}/${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}.${file.name
      .split(".")
      .pop()}`;
    var metadata = {
      customMetadata: {
        [this.auth.currentUser.uid]: "owner"
      }
    };

    this.storage.ref(filename).put(file, metadata);
    return filename;
  }
  deleteFile(filename) {
    return this.storage.ref(filename).delete();
  }

  getFile(name) {
    console.log("getFile");
    console.log(this.storage.ref(name).getMetadata());

    let url = this.storage.ref(name).getDownloadURL();
    return url;
  }

  project(projectId) {
    return this.firestore.collection("projects").doc(projectId);
  }

  projectPermission(projectId, email) {
    try {
      var addMessage = this.functions.httpsCallable("addProjectMember");
      addMessage({project: projectId, email: email}).then(result => {
        // Read result of the Cloud Function.
        //alert(result.code + " " + result.data.text);
      });
    } catch (e) {}
  }

  projectComplete(projectId) {
    return this.firestore
      .collection("projects")
      .doc(projectId)
      .update({
        status: "complete",
        updated: new Date().toISOString()
      });
  }
  projectDelete(projectId) {
    return this.firestore
      .collection("projects")
      .doc(projectId)
      .delete();
  }

  projectNoteDelete(note) {
    this.firestore
      .collection("projects")
      .doc(note.project_id)
      .update({notes: app.firestore.FieldValue.arrayRemove(note)})
      .then(snapshot => {
        // FEEDBACK !
        if (note.filename != null && note.filename != "") {
          this.deleteFile(note.filename);
        }
      });
  }

  projects() {
    return this.firestore
      .collection("projects")
      .where(`members.${this.auth.currentUser.uid}.approved`, "==", true);
    //  .where(`members.${this.auth.currentUser.uid}.role`,
    //    '==', 'owner')
    //  'in', ['owner', 'editor', 'commenter'])
    //.where('members', 'array-contains', this.auth.currentUser.uid);
  }

  tasks() {
    return this.firestore.collectionGroup("notes");
  }
}

export default Firebase;
