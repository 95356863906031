import React from 'react';
import { Link as RouterLink }from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { Paper, Container, Box, Grid, Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';

import { withAuthorization } from '../Session';
import { makeStyles } from '@material-ui/core/styles';
import { FeedbackForm } from './FeedbackForm.js';
import TasksList from '../Task';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function HomePage() {
  const classes = useStyles();

return (
  <>
  <Grid container spacing={3}>
	  <Grid item xs={7}>
		  <Paper>
		  <Card>
			<CardHeader
			title="Willkommen!"

			/>
			<CardContent>

        <Button
          component={RouterLink} to={ROUTES.PROJECTS}
                variant="outlined"

                 size="large"
                 className={classes.button}
                startIcon={<BusinessIcon />}
              >
                Objekte
          </Button>
          <Button
            component={RouterLink} to={ROUTES.TASKS}
            variant="outlined"
            size="large"
            className={classes.button}
            startIcon={<ListIcon />}
           >
                      Aufgaben
          </Button>
          <Button
            component={RouterLink} to={ROUTES.CALENDAR}
            variant="outlined"

            size="large"
            className={classes.button}
            startIcon={<EventNoteIcon />}
            >
                            Kalender
          </Button>
			</CardContent>
		  </Card>
		  </Paper>
	  </Grid>
	  	  <Grid item xs={5}>

			  <TasksList/>

		  </Grid>
  </Grid>
  <Paper>
  <Card>
	<CardHeader
	title="Feedback"
	subheader="Wir möchten uns verbessern :-)"
    />
	<CardContent>
		<FeedbackForm/>
	</CardContent>
  </Card>
  </Paper>


  </>
);
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
