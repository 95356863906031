import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Link  }from '@material-ui/core';

import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { SignInForm, SignInGoogle, SignInProvider } from './components.js';

import { PasswordForgetLink } from '../PasswordForget';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import {Container, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Button, TextField } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';


const SignInPage = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: '70vh' }}
  >

    <Grid item xs={3}>
      <Paper>
        <Container>
          <br/>
      <Typography variant="h6" component="h6" >
            Anmelden
            </Typography>
<SignInForm />
<br/>
<Link component={RouterLink} to={ROUTES.PASSWORD_FORGET}>Passwort vergessen</Link>
<br/>
  <SignInGoogle />
  <br/>
  <SignInProvider />
  <br/>
  </Container>
</Paper>
  </Grid>
</Grid>
);

/*
<SignInForm />

<PasswordForgetLink />
<SignUpLink />
*/



export default SignInPage;
