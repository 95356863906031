import React from 'react';
import { Link as RouterLink }from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';

import { Link, Container, Box, Divider, Card, CardHeader, CardContent, CardActionArea, CardActions } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (

	  <Container>
		<Card>
			<CardContent>
        <h1>Account</h1>


        {authUser.email}
			</CardContent>

			<CardActions style={{ display:'flex', justifyContent:'right' }}>

				<Link component={RouterLink} to={ROUTES.SIGN_OUT}>
				
				abmelden</Link>

			</CardActions>
		</Card>


		</Container>

    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
