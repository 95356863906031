import React, { Component } from 'react';
import { Link as RouterLink }from 'react-router-dom';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


class EmployeePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });


  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users,  loading } = this.state;

    return (
      <Paper>
	  <Container>
        <h1>Mitarbeiter</h1>

		<p>
		Mitarbeiter einladen<br/>
		<input type="text" placeholder="Email Adresse"/>
		<br/>
		<input type="checkbox"/> alle Objekte freigeben
		</p>

        {loading && <div>Loading ...</div>}

        <UserList users={users} />

	  </Container>
      </Paper>
    );
  }
}

const UserList = ({ users }) => (
<Table>
	<TableHead>
		<TableRow>
		<TableCell>Username</TableCell>
		<TableCell>E-Mail</TableCell>
		<TableCell></TableCell>
		</TableRow>
	</TableHead>
<TableBody>
    {users.map(user => (
      <TableRow>
        <TableCell>
         {user.username}
        </TableCell>
        <TableCell>
          {user.email}
        </TableCell>
		      <TableCell></TableCell>
      </TableRow>
    ))}
</TableBody>
  </Table>
);

//const condition = authUser =>
//  authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;

export default withAuthorization(condition)(EmployeePage);
